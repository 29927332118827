import { HttpClient } from '@angular/common/http';
import { effect, inject } from '@angular/core';
import { Router } from '@angular/router';
import { WA_LOCAL_STORAGE, WA_LOCATION } from '@ng-web-apis/common';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { computedPrevious } from 'ngxtension/computed-previous';
import { catchError, filter, pipe, switchMap, tap } from 'rxjs';
import { gt, valid } from 'semver';
import { version } from '../../../../package.json';
import { LocalStorageJwtService } from '../services/token-storage/local-storage-jwt.service';

export interface AppState {
  token: string;
  version: string;
  readonly remoteVersion: string;
  isOnline: boolean;
  isFullScreen: boolean;
  lastRequestTime: number | undefined;
  // Se puede salir/entrar de pantalla completa
  isInteractive: boolean;
  manualRotation: '' | '90' | '270';
}

export const AppStore = signalStore(
  {
    providedIn: 'root',
  },
  withState<AppState>({
    token: '',
    version,
    lastRequestTime: undefined,
    remoteVersion: version,
    isOnline: true,
    isFullScreen: false,
    isInteractive: true,
    manualRotation: '',
  }),
  withMethods((store, router = inject(Router), http = inject(HttpClient)) => ({
    setIsInteractive: (isInteractive: boolean) => {
      patchState(store, { isInteractive });
    },
    setIsFullScreen: (isFullScreen: boolean) => {
      patchState(store, { isFullScreen });
    },
    setManualRotation: (manualRotation: '' | '90' | '270') => {
      patchState(store, {
        manualRotation,
      });
    },
    unlink: () => {
      patchState(store, { token: '' });

      router.navigate(['/']);
    },
    setToken: (token: string) => {
      patchState(store, { token });
    },
    setIsOnline: (isOnline: boolean) => {
      patchState(store, { isOnline });
    },
    fetchRemoteVersion: rxMethod<void>(
      pipe(
        filter(() => {
          const cacheMS = 1000 * 60 * 5;
          const lastRequest = store.lastRequestTime();
          return !lastRequest || Date.now() - lastRequest > cacheMS;
        }),
        switchMap(() =>
          http
            .get('/assets/VERSION.txt', {
              responseType: 'text',
              transferCache: false,
              headers: {
                'Cache-Control': 'no-store',
              },
            })
            .pipe(
              catchError(() => version),
              tap((version) => {
                patchState(store, {
                  remoteVersion: version,
                  lastRequestTime: Date.now(),
                });
              }),
            ),
        ),
      ),
    ),
  })),
  withComputed((store) => ({
    previusConnectionStatus: computedPrevious(store.isOnline),
  })),
  withHooks({
    onInit(
      store,
      storage = inject(LocalStorageJwtService),
      localStorage = inject(WA_LOCAL_STORAGE),
      location = inject(WA_LOCATION),
    ) {
      store.fetchRemoteVersion();

      const token = storage.getItemSync();

      if (token) {
        patchState(store, { token: token.toString() });
      }

      const rotation = localStorage.getItem('rotation');

      if (rotation) {
        patchState(store, { manualRotation: rotation as '' | '90' | '270' });
      }

      effect(() => {
        storage.setItem(store.token());
      });

      effect(() => {
        localStorage.setItem('rotation', store.manualRotation());
      });

      // effect(() => {
      //   const isOnline = store.isOnline();
      //   const wasOnline = store.previusConnectionStatus();
      //   if (isOnline && isOnline !== wasOnline) {
      //     // Alos 2 segundo de volver la conexion recargamos la pagina
      //     setTimeout(() => location.reload(), 2000);
      //   }
      // });

      effect(() => {
        const currentVersion = store.version();

        console.log(`Player Version: ${currentVersion}`);
      });

      effect(() => {
        const remoteVersion = store.remoteVersion();
        const appVersion = store.version();
        const isOnline = store.isOnline();

        const CACHE_DURATION_MS = 60 * 60 * 1000;

        if (!remoteVersion || !appVersion || !isOnline) {
          return;
        }

        if (
          !valid(remoteVersion) ||
          !valid(appVersion) ||
          !gt(remoteVersion, appVersion)
        ) {
          return;
        }

        const lastReload = localStorage.getItem('lastReload');
        const now = Date.now();

        if (lastReload && now - parseInt(lastReload, 10) < CACHE_DURATION_MS) {
          return;
        }

        localStorage.setItem('lastReload', now.toString());
        location.reload();
      });
    },
  }),
);
